(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbInfo.constant:cardTypeLocal
   *
   * @description
   *
   */
  angular
    .module('wbInfo')
    .constant('cardTypeLocal', {
      "hu": [
        {
          "title": "Edenred Gyerek kártya",
          "url": "https://edenred.hu/termekeink/edenred-gyerek-kartya",
          "category": "family"
        }, {
          "title": "Edenred Társ kártya",
          "url": "https://edenred.hu/termekeink/edenred-tars-kartya",
          "category": "family"
        }, {
          "title": "Edenred Szülő kártya",
          "url": "https://edenred.hu/termekeink/edenred-szulo-kartya",
          "category": "family"
        }, {
          "title": "Edenred Komfort kártya",
          "url": "https://edenred.hu/termekeink/komfort-kartya",
          "category": "comfort"
        }, {
          "title": "Edenred Ez\u00fcst k\u00e1rtya",
          "url": "https://edenred.hu/termekeink/edenred-ezust-kartya",
          "category": "benefit"
        }, {
          "title": "Edenred Arany k\u00e1rtya",
          "url": "https://edenred.hu/termekeink/edenred-arany-kartya",
          "category": "benefit"
        }, {
          "title": "Edenred Platina k\u00e1rtya",
          "url": "https://edenred.hu/termekeink/edenred-platina-kartya",
          "category": "benefit"
        }, {
          "title": "Edenred Ticket Restaurant SZÉP Kártya",
          "url": "https://edenred.hu/ticket-restaurant-szep-kartya",
          "category": "benefit"
        }
      ],
      "en": [
        {
          "title": "Edenred Kids Card",
          "url": "https://edenred.hu/termekeink/edenred-gyerek-kartya",
          "category": "family"
        }, {
          "title": "Edenred Spouse Card",
          "url": "https://edenred.hu/termekeink/edenred-tars-kartya",
          "category": "family"
        }, {
          "title": "Edenred Parent Card",
          "url": "https://edenred.hu/termekeink/edenred-szulo-kartya",
          "category": "family"
        }, {
          "title": "Edenred Comfort Card",
          "url": "https://edenred.hu/termekeink/komfort-kartya",
          "category": "comfort"
        }, {
          "title": "Edenred Silver Card",
          "url": "https://edenred.hu/termekeink/edenred-ezust-kartya",
          "category": "benefit"
        }, {
          "title": "Edenred Gold Card",
          "url": "https://edenred.hu/termekeink/edenred-arany-kartya",
          "category": "benefit"
        }, {
          "title": "Edenred Platinum Card",
          "url": "https://edenred.hu/termekeink/edenred-platina-kartya",
          "category": "benefit"
        }, {
          "title": "Edenred Ticket Restaurant Multiwallet Card",
          "url": "https://edenred.hu/termekeink/edenred-szep-plusz-kartya",
          "category": "benefit"
        }
      ]
    });
}());
